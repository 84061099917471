<template>
  <div>
    <Modal
      v-model="flag"
      :title="id > 0 ? '部门详情' : '部门新增'"
      :mask-closable="false"
      width="400"
      @on-cancel="close"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <Form
        @submit.native.prevent
        :label-width="100"
        :model="formd"
        :rules="rules"
        ref="form"
      >
        <FormItem label="部门名称：" prop="DeptName" required>
          <Input v-if="ABLE" v-model="formd.DeptName" />
          <div v-else>{{formd.DeptName}}</div>
        </FormItem>
        <FormItem label="部门电话：">
          <Input v-if="ABLE" v-model="formd.Tel" />
          <div v-else>{{formd.Tel}}</div>
        </FormItem>
        <FormItem label="部门地址：">
          <Input v-if="ABLE" v-model="formd.Address" />
          <div v-else>{{formd.Address}}</div>
        </FormItem>
        <FormItem label="备注：">
          <Input v-if="ABLE" v-model="formd.Remark" type="textarea" />
          <div v-else>{{formd.Remark}}</div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="close">关闭</Button>
        <Button type="primary" :loading="posting" @click="ok" v-if="ABLE">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ABLE: false,
      id: 0,
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rules: {
        DeptName: [{ required: true, message: " " }],
      },
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.OrgDeptUpd
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.OrgDeptAdd
      }
    },
    close() {
      this.ABLE = false;
      this.id = 0;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    // ==========
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/OrgDept/GetById", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.failed = false;
            this.formd = res.data;
          } else {
            this.failed = true;
            if (err.errormsg) {
              this.$Message.error(err.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    postData() {
      this.posting = true;
      let api = this.id > 0 ? "/Api/OrgDept/Update" : "/Api/OrgDept/Add";
      let param = this.formd;
      this.$axios
        .post(api, param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("编辑成功");
              this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.$emit("on-add");
            }
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>